import Vue from "vue";
import VueRouter from "vue-router";
import store from "../stores/store";

import SignIn from "../views/SignIn.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import RestorePassword from "../views/RestorePassword.vue";
import SignUp from "../views/SignUp.vue";
import Step1 from "../components/Register/Step1.vue";
import Step2 from "../components/Register/Step2.vue";
import Step3 from "../components/Register/Step3.vue";
import Step4 from "../components/Register/Step4.vue";
import Step5 from "../components/Register/Step5.vue";

import emailSocial from "../components/Register/email-social.vue";

import Home from "../views/Home.vue";

import Default from "../views/Default.vue";
import Express from "../views/Express.vue";
import Program from "../views/Programmed.vue";
import PuntoScharff from "../views/PuntoScharff.vue";

import CompraTrae from "../views/CompraTrae.vue";
import Favorites from "../views/Favorites.vue";
import Cards from "../views/Cards.vue";
import History from "../views/History.vue";
import Profile from "../views/Profile.vue";
import Contacts from "../views/Contacts.vue";
import Addresses from "../views/Addresses.vue";

import DemoIndex from "../views/demo/demo-index.vue"
import DemoInput from "../views/demo/partial/demo-inputs.vue"

import ExternalSignIn from "../views/ExternalSignIn.vue";

import TrackingView from "../views/TrackingView.vue";

Vue.use(VueRouter);

const routes = [
 
  {
    path: "/",
    components: {
      a: Home,
    },
    children: [
      {
        path: "demo",
        components: {
          b: DemoIndex,
        },
      },
      {
        path:"inputs",
        components:{
          b:DemoInput,
        }
      },
      {
        path: "/",
        name: "Home",
        components: {
          b: Default,
        },
      },
      {
        path: "express",
        name: "Express",
        components: {
          b: Express,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "programmed",
        name: "Programmed",
        components: {
          b: Program,
        },
        beforeEnter: (to, from, next) => {
          next("/programado/fecha-de-recojo");
        },
      },
      {
        path: "programado/fecha-de-recojo",
        name: "ProgrammedStep1",
        components: {
          b: Program,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "programado/datos-de-recojo",
        name: "ProgrammedStep2",
        components: {
          b: Program,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "programado/gestiona-tus-paquetes",
        name: "ProgrammedStep3",
        components: {
          b: Program,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "programado/datos-de-confirmacion",
        name: "ProgrammedStep4",
        components: {
          b: Program,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "programado/pagoefectivo",
        name: "ProgrammedStep4PagoEfectivo",
        components: {
          b: Program,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "programado/confirmacion-exitosa",
        name: "ProgrammedStep5",
        components: {
          b: Program,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "puntoscharff",
        name: "PuntoScharff",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          next("/puntos-scharff")
        },
      },
      {
        path: "puntos-scharff",
        name: "PuntoScharffStep1Puntos",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/envia/datos-de-recojo",
        name: "PuntoScharffStep2Puntos",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/recojo/datos-de-recojo",
        name: "PuntoScharffStep2Pickup",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/envia/gestiona-tus-paquetes",
        name: "PuntoScharffStep3Puntos",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/recojo/gestiona-tus-paquetes",
        name: "PuntoScharffStep3Pickup",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/envia/datos-de-confirmacion",
        name: "PuntoScharffStep4Puntos",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/recojo/datos-de-confirmacion",
        name: "PuntoScharffStep4Pickup",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/envia/pagoefectivo",
        name: "PuntoScharffStep5PuntosPagoEfectivo",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/recojo/pagoefectivo",
        name: "PuntoScharffStep5PickupPagoEfectivo",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/envia/confirmacion-exitosa",
        name: "PuntoScharffStep5Puntos",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "puntos-scharff/recojo/confirmacion-exitosa",
        name: "PuntoScharffStep5Pickup",
        components: {
          b: PuntoScharff,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) { next("/signIn") }
          else if (store.getters.NEWCLIENT === true) { next("/mi-perfil") }
          else {
            if (from.name == null) { next("/") }
            next(true)
          }
        }
      },
      {
        path: "compraytrae",
        name: "compratrae",
        components: {
          b: CompraTrae,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "mis-envios",
        name: "history",
        components: {
          b: History,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "mi-perfil",
        name: "profile",
        components: {
          b: Profile,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "contactos",
        name: "contactos",
        components: {
          b: Contacts,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "mis-direcciones",
        name: "direcciones",
        components: {
          b: Addresses,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "favorites",
        name: "favorites",
        components: {
          b: Favorites,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
      {
        path: "tarjetas",
        name: "cards",
        components: {
          b: Cards,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next("/signIn");
          } else {
            if(from.name == null) {next("/");}
            next(true);
          }
        },
      },
    ],
  },
  {
    path: "/signIn",
    components: {
      a: SignIn,
    },
    children: [
      {
        path: "/",
        name: "SignIn",
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next(true);
          } else if (store.getters.NEWCLIENT === true) {
            next("/mi-perfil");
          } else {
            next("/");
          }
        },
      },
      {
        path: "fb/:accessToken",
        name: "ExternalFBLogin",
        beforeEnter: (to, from, next) => {
          const { accessToken } = to.params;

          Vue.swal({
            text: "Procesando...",
            allowOutsideClick: false,
            didOpen: () => {
              Vue.swal.showLoading();
            },
          });

          if (!accessToken) {
            Vue.swal({
              title: "Error!",
              html: "Se debe proporcionar un access token.",
              icon: "error",
              confirmButtonText: "Aceptar",
            }).then(() => {
              next("/signIn");
            });
          }
          store
            .dispatch("FB_LOGIN", {accessToken})
            .then((success) => {
              if (success.status === 200) {
                store.dispatch("USER_SET_USER", success.data.result);
                Vue.swal.close();
                if (store.getters.NEWCLIENT === true) {
                  next("/mi-perfil");
                } else {
                  next("/");
                }
              } else {
                Vue.swal({
                  title: "Error!",
                  html: success.data.message,
                  icon: "error",
                  confirmButtonText: "Aceptar",
                }).then(() => {
                  next("/signIn");
                });
              }
            })
            .catch((error) => {
              const msg = error?.data?.message || "Ocurrio un error.";
              Vue.swal({
                title: "Error!",
                html: msg,
                icon: "error",
                confirmButtonText: "Aceptar",
              }).then(() => {
                next("/signIn");
              });
            });
        },
      },
      {
        path: "external/:email/:password",
        name: "ExternalRegularLogin",
        beforeEnter: (to, from, next) => {
          Vue.swal({
            text: "Procesando...",
            allowOutsideClick: false,
            didOpen: () => {
              Vue.swal.showLoading();
            },
          });

          const emailParam = to.params.email;
          const passwordParam = to.params.password;

          if (!emailParam || !passwordParam) {
            Vue.swal({
              title: "Error!",
              html: "Ingrese un correo y contraseña correcto.",
              icon: "error",
              confirmButtonText: "Aceptar",
            }).then(() => {
              next("/signIn");
            });
          }

          store
            .dispatch("LOGINEXTERNAL", {
              email: emailParam,
              password: passwordParam,
            })
            .then((success) => {
              if (success === true) {
                Vue.swal.close();
                next("/");
              } else {
                Vue.swal({
                  title: "Error!",
                  html: "Revise sus datos e intente nuevamente.",
                  icon: "error",
                  confirmButtonText: "Aceptar",
                }).then(() => {
                  next("/signIn");
                });
              }
            })
            .catch((error) => {
              if (error === false) {
                Vue.swal({
                  title: "Error!",
                  html: "Revise sus datos e intente nuevamente.",
                  icon: "error",
                  confirmButtonText: "Aceptar",
                }).then(() => {
                  next("/signIn");
                });
              } else {
                Vue.swal({
                  title: "Error!",
                  html: "Error en el sistema, contactarse con Sharf.",
                  icon: "error",
                  confirmButtonText: "Aceptar",
                }).then(() => {
                  next("/signIn");
                });
              }
            });
        },
      },
    ],
  },
  {
    path: "/signUp",
    components: {
      a: SignUp,
    },
    children: [
      {
        path: "/",
        name: "emailSocial",
        components: {
          signUpStep: emailSocial,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.AUTHENTICATED === false) {
            next(true);
          } else {
            next("/");
          }
        },
      },
      {
        path: "additional-data",
        name: "Step1",
        components: {
          signUpStep: Step1,
        },
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('registerEmail')) {
            next(true)
          } else {
            next('/signUp/')
          }
        },
      },
      {
        path: "step2",
        name: "Step2",
        components: {
          signUpStep: Step2,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.REGISTER_STEP1 === false) {
            next("/signUp");
          } else {
            next(true);
          }
        },
      },
      {
        path: "step3",
        name: "Step3",
        components: {
          signUpStep: Step3,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.REGISTER_STEP2 === false) {
            next("/signUp");
          } else {
            next(true);
          }
        },
      },
      {
        path: "step4",
        name: "Step4",
        components: {
          signUpStep: Step4,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.REGISTER_STEP3 === false) {
            next("/signUp");
          } else {
            next(true);
          }
        },
      },
      {
        path: "step5",
        name: "Step5",
        components: {
          signUpStep: Step5,
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.REGISTER_STEP4 === false) {
            next("/signUp");
          } else {
            next(true);
          }
        },
      },
    ],
  },
  {
    path: "/forgotPassword",
    components: {
      a: ForgotPassword,
    },
    children: [
      {
        path: "/",
        name: "forgotPassword",
        components: {
          a: ForgotPassword,
        },
      },
      {
        path: "code/:link",
        name: "code",
        beforeEnter: (to, from, next) => {
          const codeExternal = to.params.link;
          store.dispatch("USER_SET_CODE_RESTORE_PASSWORD", codeExternal);
          next("/restorePassword");
        },
      },
    ],
  },
  {
    path: "/restorePassword",
    components: {
      a: RestorePassword,
    },
  },
  {
    path: "/externalLogin",
    components: { a: ExternalSignIn },
    beforeEnter: (to, from, next) => {
      if (store.getters.AUTHENTICATED === false) {
        next(true);
      } else if (store.getters.NEWCLIENT === true) {
        next("/mi-perfil");
      } else {
        next("/");
      }
    },
  },
  {
    path: "/tracking",
    components: {a: TrackingView}
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
