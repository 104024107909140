/* eslint-disable eqeqeq */
/* eslint-disable no-empty-pattern */
import axios from "axios";
import * as Sentry from "@sentry/vue";

export default {
  state: {
    progressNav: {
      progress: "25",
      stepsText: "Paso 1 de 4",
      title: "Envío Punto Sharf",
      visiblePc: "true",
      showButtonBack: "true",
      helpModalId: "modalOnboardingScharffPointApp",
      step: 1,
      show: true,
    },
    order: {
      type: "",
      deliverDate: "",
      origin: {
        favoriteLocationId: 0,
        description: "",
        lat: null,
        lng: null,
        districtId: 0,
        additionalAddress: "",
        scharffPoint: null,
        addressDetail: "",
      },
      contactOrigin: null,
      Destination: [],
      voucher: null,
      card: null,
      fare: null,
      coupon: null,
    },
    map: null,
    file: null,
    indexDestination: 0,
    modals: {
      modalOrigin: null,
      modalDestination: null,
    },
    styles: {
      mapSaio: false,
      contentDiv: "",
      generalDiv: "",
      // servicesWoMapDiv: "",
    },
  },
  mutations: {
    SET_FILE: (state, file) => {
      state.file = file;
    },
    SET_PROGRESS_NAV: (state, progressNav) => {
      state.progressNav = progressNav;
    },
    SET_PROGRESS_NAV_ATTR: (state, attr) => {
      if (attr) {
        state.progressNav.progress =
          attr.progress ?? state.progressNav.progress;
        state.progressNav.stepsText =
          attr.stepsText ?? state.progressNav.stepsText;
        state.progressNav.title = attr.title ?? state.progressNav.title;
        state.progressNav.visiblePc =
          attr.visiblePc ?? state.progressNav.visiblePc;
        state.progressNav.showButtonBack =
          attr.showButtonBack ?? state.progressNav.showButtonBack;
        state.progressNav.helpModalId =
          attr.helpModalId ?? state.progressNav.helpModalId;
        state.progressNav.step = attr.step ?? state.progressNav.step;
        state.progressNav.show = attr.show ?? state.progressNav.show;
      }
    },
    SET_PROGRESS_NAV_VISIBLEPC: (state, attr) => {
      state.progressNav.visiblePc = attr;
    },
    SET_PROGRESS_NAV_VISIBILITY: (state, show) => {
      state.progressNav.show = show;
    },
    SET_PROGRESS_NAV_STEP_PS: (state, step) => {
      const stringStep = step > 4 ? 4 : step;
      // state.progressNav.stepsText = stringStep === 1 ?`Paso ${stringStep} de 4` : '';
      state.progressNav.stepsText = stringStep === 1 ? `Paso 1 de 4` :
                                    stringStep === 2 ? 'Paso 2 de 4' :
                                    stringStep === 3 ? 'Paso 3 de 4' :
                                    stringStep === 4 ? 'Paso 4 de 4' : 'Paso 4 de 4';
      state.progressNav.title = "Envío Punto Sharf";
      state.progressNav.showButtonBack = "true";
      state.progressNav.helpModalId = "modalOnboardingScharffPointApp";

      if (step === 1) {
        state.progressNav.progress = "25";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 1;
        state.progressNav.show = true;
        state.styles.mapSaio = false;
        state.styles.contentDiv = process.env.VUE_APP_STYLE_PROGRAM_STEP_ONE;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
      } else if (step === 2) {
        state.progressNav.progress = "50";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 2;
        state.progressNav.show = true;
        if (state.order.type === "1") {
          state.styles.mapSaio = true;
          state.progressNav.title = "Envío Punto Sharf";

          state.styles.generalDiv =
            process.env.VUE_APP_STYLE_SCHARFFPOINT_STEP_TWO_TYPE_ONE;
          state.styles.contentDiv = process.env.VUE_APP_STYLE_PROGRAM_STEP_TWO;
        } else {
          state.progressNav.title = "Recojo Punto Sharf";
        }
      } else if (step === 3) {
        state.progressNav.progress = "75";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 3;
        state.progressNav.show = true;
        state.styles.mapSaio = false;
        state.progressNav.title = state.order.type == "1" ? "Envío Punto Sharf" : "Recojo Punto Sharf";
        state.styles.contentDiv = process.env.VUE_APP_STYLE_PROGRAM_STEP_ONE;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
      } else if (step === 4) {
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 4;
        state.progressNav.show = true;
        state.styles.mapSaio = false;
        state.progressNav.title = state.order.type == "1" ? "Envío Punto Sharf" : "Recojo Punto Sharf";
        state.styles.contentDiv = process.env.VUE_APP_STYLE_PROGRAM_STEP_ONE;
      } else {
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "false";
        state.progressNav.step = step;
        state.progressNav.show = false;
        state.styles.contentDiv = process.env.VUE_APP_STYLE_EXPRESS_STEP_W_BG;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_HOME;
      }
    },

    SET_STYLES_SCHARFFPOINT: (state, styles) => {
      state.styles = styles;
    },
    SET_SCHARFFPOINT_MAP: (state, map) => {
      state.map = map;
    },
    SET_ORDER: (state, order) => {
      state.order = order;
    },
    APPEND_ORDER_DESTINATION: (state, destination) => {
      state.order.Destination = destination;
    },
    CLEAR_ORDER_DESTINATION: (state) => {
      state.order.Destination = [];
    },
    SET_ORDER_SET_DESTINATION: (state, destination) => {
      const dato = state.order.Destination.length;
      if (dato <= state.indexDestination) {
        state.order.Destination.push(destination.item);
      } else {
        state.order.Destination.splice(
          destination.index + 1,
          0,
          destination.item
        );
        state.order.Destination.splice(destination.index, 1);
      }
    },
    SET_ORDER_DELETE_DESTINATION: (state, indexDestination) => {
      state.order.Destination.splice(indexDestination, 1);
    },
    SET_INDEX_DESTINATION: (state, indexDestination) => {
      state.indexDestination = indexDestination;
    },
    // Order
    SET_CONTACT_ORIGIN: (state, contactOrigin) => {
      state.order.contactOrigin = contactOrigin;
    },
    SET_ORIGIN_ADDRESS_DETAIL: (state, districtId) => {
      state.order.origin.addressDetail = districtId;
    },
    SET_ORDER_DELIVER_DATE: (state, date) => {
      state.order.deliverDate = date;
    },
    SET_ADDRESS_ORIGIN: (state, address) => {
      state.order.origin.favoriteLocationId = address.favoriteLocationId;
      state.order.origin.name = address.name;
      state.order.origin.description = address.description;
      state.order.origin.additionalAddress = address.additionalAddress;
      state.order.origin.lat = address.lat;
      state.order.origin.lng = address.lng;
      state.order.origin.departmentName = address.departmentName;
      state.order.origin.provinceName = address.provinceName;
      state.order.origin.districtName = address.districtName;
    },
    SET_ADDRESS_DISTRICT_ORIGIN: (state, districtId) => {
      state.order.origin.districtId = districtId;
    },
    SET_ADDRESS_SCHARFFOINT_ORIGIN: (state, districtId) => {
      state.order.origin.scharffPoint = districtId;
    },
    SET_ORDER_TYPE: (state, type) => {
      state.order.type = type;
    },
    SET_VOUCHER: (state, voucher) => {
      state.order.voucher = voucher;
    },
    SET_CARD: (state, voucher) => {
      state.order.card = voucher;
    },
    SET_ORDER_FARE: (state, fare) => {
      state.order.fare = fare;
    },
    SET_COUPON: (state, coupon) => {
      state.order.coupon = coupon;
    },
  },
  getters: {
    SCHARFFPOINT_FILE: (state) => state.file,
    SCHARFFPOINT_PROGRESS_NAV_STEP: (state) => state.progressNav.step,
    SCHARFFPOINT_PROGRESS_NAV: (state) => state.progressNav,
    SCHARFFPOINT_STYLES: (state) => state.styles,
    SCHARFFPOINT_ORDER: (state) => state.order,
    SCHARFFPOINT_ORIGIN_CONTACT: (state) => state.order.contactOrigin,
    SCHARFFPOINT_INDEX_DESTINATION: (state) => state.indexDestination,
    SCHARFFPOINT_ORDER_DESTINATION: (state) => state.order.Destination,
    SCHARFFPOINT_ORDER_ORIGIN: (state) => state.order.origin,
    SCHARFFPOINT_ORDER_ORIGIN_FAVORITE_ID: (state) => state.order.origin.favoriteLocationId,
    SCHARFFPOINT_ORDER_TYPE: (state) => state.order.type,
    SCHARFFPOINT_COUPON: (state) => state.order.coupon,
    SCHARFFPOINT_CARD: (state) => state.order.card,
    SCHARFFPOINT_VOUCHER: (state) => state.order.voucher,
    SCHARFFPOINT_FARE: (state) => state.order.fare,
    SCHARFFPOINT_MAP: (state) => state.map,
    SCHARFFPOINT_VALIDATE_STEP_2: (state) => {
      if (state.order.type == 1) {
        if (state.order.contactOrigin === null) return "Debe escoger un contacto de quien envía."
        if (state.order.origin.scharffPoint === null) return "Debe escoger un Punto Sharf."
      } 
      else{
        if (state.order.contactOrigin === null) return "Debe escoger un contacto de quien envía."
        if (state.order.origin.addressDetail === null) return "Debe escribir una dirección."
        // if (state.order.origin.description === "") return false;
        if (!state.order.origin.districtId.id) return "La dirección de recojo que ingresaste no tiene distrito. Puedes editarla y escoger un distrito de la lista. Alternativamente puedes crear una dirección nueva."
      }
      return 'Ok'
    },
    SCHARFFPOINT_VALIDATE_DIRECTIONS: (state) => ({
      direccion: state.order.origin.description,
      referencia: state.order
    }),
    SCHARFFPOINT_FARE_NO_DISCOUNT(state) {
      return state.order.Destination.reduce((sum, dest) => sum + dest.fare, 0)
    },
    SCHARFFPOINT_FARE_WITH_DISCOUNT(state) {
      return state.order.Destination.reduce((sum, dest) => {
        const fareWithDiscount = dest.discountFare || 0
        return sum + fareWithDiscount
      }, 0)
    },
    SCHARFFPOINT_PAID_DEST_FARE_NO_DISCOUNT(state) {
      return state.order.Destination.reduce((sum, dest) => {
        if (dest.isShippingPaidAtDestination) {
          return sum + dest.fare
        }
        return sum
      }, 0)
    },
    SCHARFFPOINT_PAID_DEST_FARE_WITH_DISCOUNT(state) {
      return state.order.Destination.reduce((sum, dest) => {
        if (dest.isShippingPaidAtDestination) {
          const fareWithDiscount = parseFloat(dest.discountFare) || 0
          return sum + fareWithDiscount
        }
        return sum
      }, 0)
    },
    SCHARFFPOINT_PREPAID_FARE_NO_DISCOUNT(state) {
      return state.order.Destination.reduce((sum, dest) => {
        if (!dest.isShippingPaidAtDestination) {
          return sum + dest.fare
        }
        return sum
      }, 0)
    },
    SCHARFFPOINT_PREPAID_FARE_WITH_DISCOUNT(state) {
      return state.order.Destination.reduce((sum, dest) => {
        if (!dest.isShippingPaidAtDestination) {
          const fareWithDiscount = parseFloat(dest.discountFare) || 0
          return sum + fareWithDiscount
        }
        return sum
      }, 0)
    },
    IS_DESTINATION_PAYMENT_FOR_ALL_PACKAGES: (state) => state.order.Destination.every(dest => dest.isShippingPaidAtDestination),
    IS_DESTINATION_PAYMENT_FOR_SOME_PACKAGES: (state) => state.order.Destination.some(dest => dest.isShippingPaidAtDestination),
    IS_PREPAID_FOR_ALL_PACKAGES: (state) => state.order.Destination.every(dest => !dest.isShippingPaidAtDestination),
    IS_PREPAID_FOR_SOME_PACKAGES: (state) => state.order.Destination.some(dest => !dest.isShippingPaidAtDestination),
  },
  actions: {
    SCHARFFPOINT_FILE: ({ commit }, file) => {
      commit("SET_FILE", file);
    },
    SCHARFFPOINT_PROGRESS_NAV_ATTR: ({ commit }, attr) => {
      commit("SET_PROGRESS_NAV_ATTR", attr);
    },
    SCHARFFPOINT_PROGRESS_NAV_VISIBLEPC: ({ commit }, attr) => {
      commit("SET_PROGRESS_NAV_VISIBLEPC", attr);
    },
    SCHARFFPOINT_PROGRESS_NAV_VISIBILITY: ({ commit }, show) => {
      commit("SET_PROGRESS_NAV_VISIBILITY", show);
    },
    SCHARFFPOINT_PROGRESS_NAV_STEP: ({ commit }, step) => {
      commit("SET_PROGRESS_NAV_STEP_PS", step);
    },
    SCHARFFPOINT_ORDER_DELIVER_DATE: ({ commit }, date) => {
      commit("SET_ORDER_DELIVER_DATE", date);
    },

    // Set Order

    SCHARFFPOINT_ORDER_ORIGIN_CONTACT: ({ commit }, originContact) => {
      commit("SET_CONTACT_ORIGIN", originContact);
    },
    SCHARFFPOINT_ORDER_ORIGIN_ADDRESS_DETAIL: ({ commit }, districtId) => {
      commit("SET_ORIGIN_ADDRESS_DETAIL", districtId);
    },

    SCHARFFPOINT_ORDER_ORIGIN_ADDRESS: ({ commit }, address) => {
      commit("SET_ADDRESS_ORIGIN", address);
    },
    SCHARFFPOINT_ORDER_ORIGIN_SCHARFFPOINT: ({ commit }, address) => {
      commit("SET_ADDRESS_SCHARFFOINT_ORIGIN", address);
    },
    SCHARFFPOINT_ORDER_ORIGIN_DISTRICT: ({ commit }, districtId) => {
      commit("SET_ADDRESS_DISTRICT_ORIGIN", districtId);
    },

    SCHARFFPOINT_ORDER_SET_DESTINATION: ({ commit }, destination) => {
      commit("SET_ORDER_SET_DESTINATION", destination);
    },

    SCHARFFPOINT_ORDER_DELETE_DESTINATION: ({ commit }, indexDestination) => {
      commit("SET_ORDER_DELETE_DESTINATION", indexDestination);
    },

    SCHARFFPOINT_ORDER_SET_INDEX_DESTINATION: ({ commit }, index) => {
      commit("SET_INDEX_DESTINATION", index);
    },
    SCHARFFPOINT_MAP_SET: ({ commit }, map) => {
      commit("SET_SCHARFFPOINT_MAP", map);
    },
    SCHARFFPOINT_ORDER_TYPE: ({ commit }, type) => {
      commit("SET_ORDER_TYPE", type);
    },
    SCHARFFPOINT_CALCULATE_FARE: ({}, fare) =>
      new Promise((resolve, reject) => {
        axios
          .post(`fare`, fare)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data.result);
            } else if (response.status === 204) {
              reject(new Error("Faltan datos por completar"));
            } else {
              reject(new Error("Ocurrió un error en el servidor"));
            }
          })
          .catch((error) => {
            Sentry.captureException(error, {
              tags: {
                ErrorType: "Prioritize",
              },
            });
            reject(new Error(error));
          });
      }),

    SCHARFFPOINT_VOUCHER: ({ commit }, voucher) => {
      commit("SET_VOUCHER", voucher);
    },
    SCHARFFPOINT_CARD: ({ commit }, voucher) => {
      commit("SET_CARD", voucher);
    },
    SCHARFFPOINT_FARE: ({ commit }, fare) => {
      commit("SET_ORDER_FARE", fare);
    },
    SCHARFFPOINT_COUPON: ({ commit }, coupon) => {
      commit("SET_COUPON", coupon);
    },
    SCHARFFPOINT_APPEND_DESTINATION: ({ commit }, destination) => {
      commit("APPEND_ORDER_DESTINATION", destination);
    },
    SCHARFFPOINT_CLEAR_DESTINATION: ({ commit }, destination) => {
      commit("CLEAR_ORDER_DESTINATION", destination);
    },
    SCHARFFPOINT_CREATE_FARE: ({ state, rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        const { uuid } = rootState.userModule;

        if (!user) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(true);
        } else {
          const { order } = state;
          const postMultiOrderRequestModel = {
            orders: [],
            isSubscriptionUsed: false,
            uuid,
            userId: user.id,
          };

          order.Destination.forEach((dest) => {
            const originLat =
              order.type === "2"
                ? order.origin.lat
                : order.origin.scharffPoint.Location.lat;

            const originLng =
              order.type === "2"
                ? order.origin.lng
                : order.origin.scharffPoint.Location.lng;

            const addresses = [];
            const originLocation = {
              Direccion:
                order.type === "2"
                  ? order.origin.description
                  : order.origin.scharffPoint.Address,
              Orden: 1,
              Tipo: 1,
              DistrictId: order.origin.districtId.id,
              scharffPointId:
                order.type === "2"
                  ? null
                  : order.origin.scharffPoint.ScharffPointId,
              Contacto: order.contactOrigin.name,
              Celular: order.contactOrigin.phone,
              CorreoContacto: order.contactOrigin.email,
              DNIContacto: order.contactOrigin.documentNumber,
              Location: {
                Lat: originLat || 0,
                Lng: originLng || 0,
              },
              Detalle: order.origin.addressDetail,
            };
            addresses.push(originLocation);

            const bill = {
              RUC:
                order.voucher.typeVoucher === "Factura"
                  ? order.voucher.documentNumber
                  : null,
              BusinessName:
                order.voucher.typeVoucher === "Factura"
                  ? order.voucher.name
                  : null,
              ContactName:
                order.voucher.typeVoucher === "Factura"
                  ? order.voucher.name
                  : null,
              ContactEmail:
                order.voucher.typeVoucher === "Factura"
                  ? order.voucher.email
                  : null,
              DNI:
                order.voucher.typeVoucher === "Boleta"
                  ? order.voucher.documentNumber
                  : null,
              Name:
                order.voucher.typeVoucher === "Boleta"
                  ? order.voucher.name
                  : null,
              Email:
                order.voucher.typeVoucher === "Boleta"
                  ? order.voucher.email
                  : null,
              Cellphone: order.voucher.phone,
            };

            const orderRequest = {
              programmedServiceDate: order.deliverDate,
              packageValue: dest.productValue,
              packageDescription: dest.description,
              packageWidth: dest.packageSize.width,
              PackageHeight: dest.packageSize.height,
              PackageDeep: dest.packageSize.lengths,
              PackageWeight: dest.packageSize.weight,
              packageSize:
                dest.packageSize.type === "Pequeno" ||
                dest.packageSize.type === "Pequeño"
                  ? "Pequeño"
                  : dest.packageSize.type,
              serviceTypeId: 4, // refactor
              propina: null,
              isServiceExpress: false,
              isServiceProgrammed: false,
              IsServiceScharffPoint: true,
              addresses,
              isReturnOrigin: false, // todo
              isInsuranceAccepted: false, // todo,
              bill,
              coupon: order.coupon ? order.coupon.code : null,
              creditCardId: user.esUsuarioProgramado ? null : order?.card?.cardId,
              isShippingPaidAtDestination: dest.isShippingPaidAtDestination,
              fragil: dest.fragil ?? dest.isFragil,
              secondAttemptInPS: dest.secondAttemptInPS,
              SubTypeSelected: order.type === "1" ? 2 : 1,
              isPointToPoint: dest.isPointPoint,
            };
            const destLat =
              order.type === "2" || (order.type === "1" && dest.isPointPoint)
                ? dest.destinationAddress.puntoScharffId.Location.Lat
                : dest.destinationAddress.lat;

            const destLng =
              order.type === "2" || (order.type === "1" && dest.isPointPoint)
                ? dest.destinationAddress.puntoScharffId.Location.Lng
                : dest.destinationAddress.lng;

            addresses.push({
              Direccion:
                order.type === "2" || (order.type === "1" && dest.isPointPoint)
                  ? dest.destinationAddress.puntoScharffId.address
                  : dest.destinationAddress.description,
              Orden: 2,
              Tipo: 2,
              DistrictId: dest.destinationAddress.districtId.id,
              ScharffPointId:
                order.type === "2" || (order.type === "1" && dest.isPointPoint)
                  ? dest.destinationAddress.puntoScharffId.ScharffPointId
                  : null,
              Contacto: dest.destinationAddress.contact.name,
              Celular: dest.destinationAddress.contact.phone,
              CorreoContacto: dest.destinationAddress.contact.email,
              DNIContacto: dest.destinationAddress.contact.documentNumber,
              DireccionAdicional: dest.destinationAddress.additionalAddress,

              Location: {
                Lat: destLat || 0,
                Lng: destLng || 0,
              },

            });
            postMultiOrderRequestModel.orders.push(orderRequest);
          });

          resolve(postMultiOrderRequestModel);
        }
      }),
    SCHARFFPOINT_PROCESS_EXCEL: ({ state, rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(true);
        } else {
          // DROPOFF es Envio, PickUp es recojo
          const endpoint =
            state.order.type === "1" ? "processPSEnvio" : "processPSRecojo";
          const formData = new FormData();
          state.file.forEach((f) => {
            formData.append("excelFile", f);
          });
          axios
            .post(`multiOrderFile/${endpoint}`, formData, {
              headers: {
                Authorization: `Bearer ${user.token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
  },
};
