import * as Sentry from "@sentry/vue";
import { api2 } from "./api";
import store from "../stores/store";

const { user } = store.state.userModule;

const addCard = async (data) => {
  try {
    const res = await api2.post(`client/${user.id}/addCard`, data);
    return res.data;
  } catch (error) {
    Sentry.configureScope((scope) => {
      scope.setTransactionName("CreateCardError");
      scope.setLevel("error");
      scope.setExtra("data", JSON.stringify(data));
      scope.setExtra("response", JSON.stringify(error.response));
      Sentry.captureException(new Error("Failed Add Card to user"));
    });
    console.error(error.response);
    return error.response;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { addCard };
