export default {
  state: {
    reload: true,
    // Express = 1
    // Programado = 2
    // CompraTrae = 3
    // PuntoScharff = 4
    currentService: 0,
    modalContactType: "",
    addAddress: {
      additionalAddress: "",
      description: "",
      lat: 0,
      lng: 0,
    },
    contactDestination: null,
    currentNavSection: "servicio",
    currentCardToEdit: null,
    currentCardSection: "list",

    serviceTypeForSurvey: 'Puntos Scharff'
  },
  mutations: {
    SET_CURRENT_CARD_SECTION: (state, section) => {
      state.currentCardSection = section;
    },
    SET_CURRENT_CARD_TO_EDIT: (state, card) => {
      state.currentCardToEdit = card;
    },
    SET_CURRENT_NAV_SECTION: (state, section) => {
      state.currentNavSection = section;
    },
    SET_ADD_ADDRESS: (state, address) => {
      state.addAddress = address;
    },
    SET_RELOAD: (state, reload) => {
      state.reload = reload;
    },
    SET_CURRENT_SERVICE: (state, currentService) => {
      state.currentService = currentService;
    },
    SET_MODAL_CONTACT_TYPE: (state, type) => {
      state.modalContactType = type;
    },
    SET_GENERAL_CONTACT_DESTINATION: (state, contact) => {
      state.contactDestination = contact;
    },
    SET_SERVICE_TYPE_FOR_SURVEY: (state, serviceType) => {
      state.serviceTypeForSurvey = serviceType
    },
  },
  getters: {
    HELPER_CURRENT_CARD_SECTION: (state) => state.currentCardSection,
    HELPER_CURRENT_CARD_TO_EDIT: (state) => state.currentCardToEdit,
    HELPER_CURRENT_NAV_SECTION: (state) => state.currentNavSection,
    RELOAD: (state) => state.reload,
    CURRENT_SERVICE: (state) => state.currentService,
    MODAL_CONTACT_TYPE: (state) => state.modalContactType,
    ADD_ADDRESS: (state) => state.addAddress,
    GENERAL_DESTINATION_CONTACT: (state) => state.contactDestination,
    SERVICE_TYPE_FOR_SURVEY: (state) => state.serviceTypeForSurvey,
  },
  actions: {
    HELPER_SET_CURRENT_CARD_SECTION: ({ commit }, section) => {
      commit("SET_CURRENT_CARD_SECTION", section);
    },
    HELPER_SET_CURRENT_CARD_TO_EDIT: ({ commit }, card) => {
      commit("SET_CURRENT_CARD_TO_EDIT", card);
    },
    HELPER_SET_CURRENT_NAV_SECTION: ({ commit }, section) => {
      commit("SET_CURRENT_NAV_SECTION", section);
    },
    RELOAD_PAGE: ({ commit }, reload) => {
      commit("SET_RELOAD", reload);
    },
    CURRENT_SERVICE: ({ commit }, currentService) => {
      commit("SET_CURRENT_SERVICE", currentService);
    },
    HELPER_SET_MODAL_CONTACT_TYPE: ({ commit }, type) => {
      commit("SET_MODAL_CONTACT_TYPE", type);
    },
    HELPER_SET_ADD_ADDRESS: ({ commit }, address) => {
      commit("SET_ADD_ADDRESS", address);
    },
    HELPER_SET_ADD_DESTINATION_CONTACT: ({ commit }, contact) => {
      commit("SET_GENERAL_CONTACT_DESTINATION", contact);
    },
    HELPER_SET_SERVICE_TYPE_FOR_SURVEY: ({ commit }, serviceType) => {
      commit("SET_SERVICE_TYPE_FOR_SURVEY", serviceType)
    },
  },
};
