/* eslint-disable no-empty-pattern */
import * as Sentry from "@sentry/vue";
import axios from "axios";

export default {
  state: {
    progressNav: {
      progress: "20",
      stepsText: "Paso 1 de 5",
      title: "Compra y Trae",
      visiblePc: "true",
      showButtonBack: "true",
      helpModalId: null,
      step: 1,
      show: true,
    },
    categories: [],
    order: {
      destination: {
        address: {
          additionalAddress: "",
          description: "",
          lat: null,
          lng: null,
        },
        districtId: {},
        provinceId: 0,
        departmentId: 0,
      },
      contactDestination: null,
      categoryIds: [],
      value: 0,
      quantity: 0,
      weight: 1,
      voucherImages: [],
      otherCategoryText: "",
      fare: { total: 0 },
      passportImageFront: [],
      passportImageBack: [],
      consignee: {
        name: "",
        lastname: "",
        phone: "",
        documentNumber: "",
        documentTypeId: "",
      },
      selectedDocumentType: {},
      voucher: null,
      card: null,
      coupon: null,
    },
    modals: {
      modalDestination: null,
    },
    styles: {
      mapSaio: false,
      showQuoter: false,
      generalDiv: "",
      // servicesWoMapDiv: "",
    },
  },
  mutations: {
    SET_COMPRA_TRAE_VALUE: (state, value) => {
      state.order.value = value;
    },
    SET_STYLES_CT: (state, styles) => {
      state.styles = styles;
    },
    SET_PROGRESS_NAV: (state, progressNav) => {
      state.progressNav = progressNav;
    },
    SET_PROGRESS_NAV_ATTR: (state, attr) => {
      if (attr) {
        state.progressNav.progress =
          attr.progress ?? state.progressNav.progress;
        state.progressNav.stepsText =
          attr.stepsText ?? state.progressNav.stepsText;
        state.progressNav.title = attr.title ?? state.progressNav.title;
        state.progressNav.visiblePc =
          attr.visiblePc ?? state.progressNav.visiblePc;
        state.progressNav.showButtonBack =
          attr.showButtonBack ?? state.progressNav.showButtonBack;
        state.progressNav.helpModalId =
          attr.helpModalId ?? state.progressNav.helpModalId;
        state.progressNav.step = attr.step ?? state.progressNav.step;
        state.progressNav.show = attr.show ?? state.progressNav.show;
      }
    },
    SET_PROGRESS_NAV_VISIBLEPC: (state, attr) => {
      state.progressNav.visiblePc = attr;
    },
    SET_PROGRESS_NAV_VISIBILITY: (state, show) => {
      state.progressNav.show = show;
    },
    SET_PROGRESS_NAV_STEP: (state, step) => {
      const stringStep = step > 5 ? 5 : step;
      state.progressNav.stepsText = `Paso ${stringStep} de 5`;
      state.progressNav.title = "Compra y trae";
      state.progressNav.showButtonBack = "true";
      state.progressNav.helpModalId = null;

      if (step === 1) {
        state.progressNav.progress = "20";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 1;
        state.progressNav.show = true;
        state.styles.mapSaio = false;
        state.styles.showQuoter = true;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
      } else if (step === 2) {
        state.progressNav.progress = "40";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 2;
        state.progressNav.show = true;
        state.styles.mapSaio = false;
        state.styles.showQuoter = false;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
      } else if (step === 3) {
        state.progressNav.progress = "60";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 3;
        state.progressNav.show = true;
        state.styles.mapSaio = false;
        state.styles.showQuoter = false;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
      } else if (step === 4) {
        state.progressNav.progress = "80";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 4;
        state.progressNav.show = true;
        state.styles.mapSaio = false;
        state.styles.showQuoter = false;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
      } else if (step === 5) {
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 5;
        state.progressNav.show = true;
        state.styles.mapSaio = false;
        state.styles.showQuoter = false;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
      } else if (step === 6) {
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "false";
        state.progressNav.step = 6;
        state.progressNav.show = false;
        state.styles.mapSaio = false;
        state.styles.showQuoter = false;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_HOME;
      } else {
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "false";
        state.progressNav.step = step;
        state.progressNav.show = false;
        state.styles.mapSaio = false;
        state.styles.showQuoter = false;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
      }
    },

    SET_COUPON: (state, coupon) => {
      state.order.coupon = coupon;
    },
    SET_CARD: (state, card) => {
      state.order.card = card;
    },
    SET_VOUCHER: (state, voucher) => {
      state.order.voucher = voucher;
    },

    SET_ORDER: (state, order) => {
      state.order = order;
    },

    SET_CATEGORIES: (state, categories) => {
      state.categories = categories;
    },

    // Order
    SET_CONTACT_DESTINATION: (state, contactDestination) => {
      state.order.contactDestination = contactDestination;
    },

    SET_DESTINATION_DISTRICT: (state, districtId) => {
      state.order.destination.districtId = districtId;
    },
    SET_DESTINATION_PROVINCE: (state, provinceId) => {
      state.order.destination.provinceId = provinceId;
    },
    SET_DESTINATION_DEPARTMENT: (state, departmentId) => {
      state.order.destination.departmentId = departmentId;
    },

    SET_CATEGORY_IDS: (state, categoryIds) => {
      state.order.categoryIds = categoryIds;
    },
    SET_VALUE: (state, value) => {
      state.order.value = value;
    },
    SET_QUANTITY: (state, quantity) => {
      state.order.quantity = quantity;
    },
    SET_WEIGHT: (state, weight) => {
      state.order.weight = weight;
    },
    SET_VOUCHER_IMAGES: (state, voucherImages) => {
      state.order.voucherImages = voucherImages;
    },
    SET_OTHER_CATEGORY_TEXT: (state, otherCategoryText) => {
      state.order.otherCategoryText = otherCategoryText;
    },
    SET_FARE: (state, fare) => {
      state.order.fare = fare;
    },

    SET_ORDER_CONSIGNEE_LASTNAME: (state, lastname) => {
      state.order.consignee.lastname = lastname;
    },

    SET_ORDER_CONSIGNEE_NAME: (state, name) => {
      state.order.consignee.name = name;
    },

    SET_ORDER_CONSIGNEE_PHONE: (state, phone) => {
      state.order.consignee.phone = phone;
    },

    SET_ORDER_CONSIGNEE_DOCUMENT_NUMBER: (state, documentNumber) => {
      state.order.consignee.documentNumber = documentNumber;
    },

    SET_ORDER_SELECTED_DOCUMENT_TYPE: (state, documentType) => {
      state.order.selectedDocumentType = documentType;
    },

    SET_ORDER_PASSPORT_IMAGE_FRONT: (state, images) => {
      state.order.passportImageFront = images;
    },

    SET_ORDER_PASSPORT_IMAGE_BACK: (state, images) => {
      state.order.passportImageBack = images;
    },

    SET_ADDRESS_DESTINATION: (state, address) => {
      state.order.destination.address = address;
    },
  },
  getters: {
    COMPRATRAE_PROGRESS_NAV: (state) => state.progressNav,
    COMPRATRAE_ORDER: (state) => state.order,
    COMPRATRAE_STEP: (state) => state.progressNav.step,
    COMPRATRAE_STYLES: (state) => state.styles,

    COMPRATRAE_COUPON: (state) => state.order.coupon,

    COMPRATRAE_CARD: (state) => state.order.card,

    COMPRATRAE_VOUCHER: (state) => state.order.voucher,

    COMPRATRAE_DESTINATION_CONTACT: (state) => state.order.contactDestination,

    COMPRATRAE_DESTINATION_DISTRICT: (state) =>
      state.order.destination.districtId,

    COMPRATRAE_CATEGORIES: (state) => state.order.categoryIds,
    COMPRATRAE_CATEGORIES_BY_ID: (state) => (id) =>
      state.categories.find((category) => category.id === id),
    COMPRATRAE_ORDER_CATEGORY_IDS: (state) => state.order.categoryIds,
    COMPRATRAE_CATEGORY_TEXT: (state) => state.order.otherCategoryText,
    COMPRATRAE_ORDER_VALUE: (state) => state.order.value,
    COMPRATRAE_ORDER_QUANTITY: (state) => state.order.quantity,
    COMPRATRAE_ORDER_WEIGHT: (state) => state.order.weight,
    COMPRATRAE_ORDER_VOUCHER_IMAGES: (state) => state.order.voucherImages,
    COMPRATRAE_ORDER_OTHER_CATEGORY_TEXT: (state) =>
      state.order.otherCategoryText,
    COMPRATRAE_ORDER_FARE: (state) => state.order.fare,

    COMPRATRAE_ORDER_CONSIGNEE_DOCUMENT_TYPE_ID: (state) =>
      state.order.consignee.documentTypeId,
    COMPRATRAE_ORDER_CONSIGNEE_LASTNAME: (state) =>
      state.order.consignee.lastname,
    COMPRATRAE_ORDER_CONSIGNEE_NAME: (state) => state.order.consignee.name,
    COMPRATRAE_ORDER_CONSIGNEE_PHONE: (state) => state.order.consignee.phone,
    COMPRATRAE_ORDER_CONSIGNEE_DOCUMENT_NUMBER: (state) =>
      state.order.consignee.documentNumber,
    COMPRATRAE_ORDER_SELECTED_DOCUMENT_TYPE: (state) =>
      state.order.selectedDocumentType,

    COMPRATRAE_ORDER_PASSPORT_IMAGE_FRONT: (state) =>
      state.order.passportImageFront,
    COMPRATRAE_ORDER_PASSPORT_IMAGE_BACK: (state) =>
      state.order.passportImageBack,

    COMPRATRAE_ORDER_DESTINATION_ADDRESS: (state) =>
      state.order.destination.address,
  },
  actions: {
    COMPRATRAE_PROGRESS_NAV_ATTR: ({ commit }, attr) => {
      commit("SET_PROGRESS_NAV_ATTR", attr);
    },
    COMPRATRAE_PROGRESS_NAV_VISIBLEPC: ({ commit }, attr) => {
      commit("SET_PROGRESS_NAV_VISIBLEPC", attr);
    },
    COMPRATRAE_PROGRESS_NAV_VISIBILITY: ({ commit }, show) => {
      commit("SET_PROGRESS_NAV_VISIBILITY", show);
    },
    COMPRATRAE_PROGRESS_NAV_STEP: ({ commit }, step) => {
      commit("SET_PROGRESS_NAV_STEP", step);
    },
    COMPRATRAE_CONTENT: ({ commit }, content) => {
      commit("SET_CONTENT", content);
    },
    COMPRATRAE_COUPON: ({ commit }, coupon) => {
      commit("SET_COUPON", coupon);
    },
    COMPRATRAE_CARD: ({ commit }, card) => {
      commit("SET_CARD", card);
    },
    COMPRATRAE_VOUCHER: ({ commit }, voucher) => {
      commit("SET_VOUCHER", voucher);
    },
    COMPRATRAE_CALCULATE_FARE: ({}, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post(`fare/compraTrae`, payload)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data.result);
            } else if (response.status === 204) {
              reject(new Error("Faltan datos por completar"));
            } else {
              reject(new Error("Ocurrió un error en el servidor"));
            }
          })
          .catch((error) => {
            Sentry.captureException(error, {
              tags: {
                ErrorType: "Prioritize",
              },
            });
            reject(new Error(error));
          });
      }),
    COMPRATRAE_FETCH_CATEGORIES: ({}) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          // Simulate server latency
          axios
            .get(`feed/descriptions`)
            .then((response) => {
              if (response.status === 200) {
                resolve(response.data.result);
              } else {
                reject(new Error("No se pudieron obtener las categorias."));
              }
            })
            .catch((error) => {
              reject(new Error(error));
            });
        }, 0);
      }),

    COMPRATRAE_CREATE_ORDER: ({ state, rootState }) => {
      const { user } = rootState.userModule;
      const UUID = rootState.userModule.uuid;

      const bill = {
        RUC:
          state.order.voucher.typeVoucher === "Factura"
            ? state.order.voucher.documentNumber
            : null,
        BusinessName:
          state.order.voucher.typeVoucher === "Factura"
            ? state.order.voucher.name
            : null,
        ContactName:
          state.order.voucher.typeVoucher === "Factura"
            ? state.order.voucher.name
            : null,
        ContactEmail:
          state.order.voucher.typeVoucher === "Factura"
            ? state.order.voucher.email
            : null,
        DNI:
          state.order.voucher.typeVoucher === "Boleta"
            ? state.order.voucher.documentNumber
            : null,
        Name:
          state.order.voucher.typeVoucher === "Boleta"
            ? state.order.voucher.name
            : null,
        Email:
          state.order.voucher.typeVoucher === "Boleta"
            ? state.order.voucher.email
            : null,
        Cellphone: state.order.voucher.phone,
      };

      const body = {
        SenderName: `${state.order.consignee.name} ${state.order.consignee.lastname}`,
        SaveData: false,
        Fragil: false,
        DocumentType: state.order.selectedDocumentType.Id,
        DocumentNumber: state.order.consignee.documentNumber,
        Descripcion: state.order.categoryIds,
        DescriptionTexto: state.order.otherCategoryText,
        Cantidad: state.order.quantity,
        Weight: state.order.weight,
        Price: state.order.value,
        Address: {
          Direccion: state.order.destination.address.description,
          DireccionAdicional: state.order.destination.address.additionalAddress,
          Detalle: "",
          Instrucciones: "",
          Contacto: state.order.contactDestination.name,
          Celular: state.order.contactDestination.phone,
          Distrito: state.order.destination.districtId.id,
          Provincia: state.order.destination.provinceId,
          Departamento: state.order.destination.departmentId,
          Location: null,
        },
        CouponId: state.order.coupon !== null ? state.order.coupon.id : null,
        CreditCardId: state.order?.card?.cardId,
        bill,
        Fare: state.order.fare,
        UUID,
      };

      const formData = new FormData();

      formData.append("model", JSON.stringify(body));

      state.order.voucherImages.forEach((image) => {
        formData.append("voucher", image);
      });

      state.order.passportImageFront.forEach((image) => {
        formData.append("passport", image);
      });

      state.order.passportImageBack.forEach((image) => {
        formData.append("passport", image);
      });

      return new Promise((resolve, reject) => {
        axios
          .post(`clients/${user.id}/compraTraeOrders`, formData, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    COMPRATRAE_CATEGORIES: ({ commit }, categories) => {
      commit("SET_CATEGORIES", categories);
    },
    // Set Order
    COMPRATRAE_ORDER_DESTINATION_CONTACT: ({ commit }, destinationContact) => {
      commit("SET_CONTACT_DESTINATION", destinationContact);
    },

    COMPRATRAE_ORDER_DESTINATION_ADDRESS: ({ commit }, address) => {
      commit("SET_ADDRESS_DESTINATION", address);
    },

    COMPRATRAE_ORDER_CATEGORY_IDS: ({ commit }, categoryIds) => {
      commit("SET_CATEGORY_IDS", categoryIds);
    },
    COMPRATRAE_ORDER_VALUE: ({ commit }, value) => {
      commit("SET_VALUE", value);
    },
    COMPRATRAE_ORDER_QUANTITY: ({ commit }, quantity) => {
      commit("SET_QUANTITY", quantity);
    },
    COMPRATRAE_ORDER_WEIGHT: ({ commit }, weight) => {
      commit("SET_WEIGHT", weight);
    },
    COMPRATRAE_ORDER_VOUCHER_IMAGES: ({ commit }, voucherImages) => {
      commit("SET_VOUCHER_IMAGES", voucherImages);
    },
    COMPRATRAE_ORDER_OTHER_CATEGORY_TEXT: ({ commit }, otherCategoryText) => {
      commit("SET_OTHER_CATEGORY_TEXT", otherCategoryText);
    },
    COMPRATRAE_ORDER_FARE: ({ commit }, fare) => {
      commit("SET_FARE", fare);
    },

    COMPRATRAE_ORDER_CONSIGNEE_DOCUMENT_TYPE_ID: (
      { commit },
      documentTypeId
    ) => {
      commit("SET_ORDER_CONSIGNEE_DOCUMENT_TYPE_ID", documentTypeId);
    },

    COMPRATRAE_ORDER_CONSIGNEE_DOCUMENT_NUMBER: (
      { commit },
      documentNumber
    ) => {
      commit("SET_ORDER_CONSIGNEE_DOCUMENT_NUMBER", documentNumber);
    },

    COMPRATRAE_ORDER_CONSIGNEE_LASTNAME: ({ commit }, lastname) => {
      commit("SET_ORDER_CONSIGNEE_LASTNAME", lastname);
    },

    COMPRATRAE_ORDER_CONSIGNEE_NAME: ({ commit }, name) => {
      commit("SET_ORDER_CONSIGNEE_NAME", name);
    },

    COMPRATRAE_ORDER_CONSIGNEE_PHONE: ({ commit }, phone) => {
      commit("SET_ORDER_CONSIGNEE_PHONE", phone);
    },

    COMPRATRAE_ORDER_SELECTED_DOCUMENT_TYPE: ({ commit }, documentType) => {
      commit("SET_ORDER_SELECTED_DOCUMENT_TYPE", documentType);
    },

    COMPRATRAE_ORDER_PASSPORT_IMAGE_FRONT: ({ commit }, passportImageFront) => {
      commit("SET_ORDER_PASSPORT_IMAGE_FRONT", passportImageFront);
    },

    COMPRATRAE_ORDER_PASSPORT_IMAGE_BACK: ({ commit }, passportImageBack) => {
      commit("SET_ORDER_PASSPORT_IMAGE_BACK", passportImageBack);
    },

    COMPRATRAE_DESTINATION_DISTRICT: ({ commit }, district) => {
      commit("SET_DESTINATION_DISTRICT", district);
    },

    COMPRATRAE_DESTINATION_PROVINCE: ({ commit }, province) => {
      commit("SET_DESTINATION_PROVINCE", province);
    },

    COMPRATRAE_DESTINATION_DEPARTMENT: ({ commit }, department) => {
      commit("SET_DESTINATION_DEPARTMENT", department);
    },
  },
};
