import { api } from "./api";

const user = JSON.parse(localStorage.getItem("vuex"))?.userModule?.user;

const getDefaultContact = async () => {
  try {
    const res = await api.get(`contacts/${user.id}/contactDefault`);
    return res.data.result;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getDefaultContact };
