import { api } from "./api";
// import store from "../stores/store";

// const { user } = store.state.userModule;

// eslint-disable-next-line import/prefer-default-export
export async function getCompraTraeCategories() {
  const res = await api.request({
    method: "get",
    url: `feed/descriptions`,
  });
  return res.data.result || false;
}
