import { api2 } from './api'

const sendSurveyResponses = async data => {
	try {
		return api2.post(`survey/save`, data)
	} catch (error) {
		return error.response
	}
}

export {
  sendSurveyResponses
}
