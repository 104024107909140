/* eslint-disable no-empty-pattern */
import axios from "axios";

export default {
  state: {
    register: {
      name: "",
      lastName: "",
      email: "",
      phone: "",
      documentTypeId: 0,
      documentNumber: "",
      password: "",
      confirmPassword: "",
      personType: false,
    },
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  },
  mutations: {
    SET_REGISTER: (state, data) => {
      if (data.name) {
        state.register.name = data.name;
      }

      if (data.password) {
        state.register.password = data.password;
      }

      if (data.confirmPassword) {
        state.register.confirmPassword = data.confirmPassword;
      }

      if (data.lastName) {
        state.register.lastName = data.lastName;
      }

      if (data.email) {
        state.register.email = data.email;
      }

      if (data.phone) {
        state.register.phone = data.phone;
      }

      if (data.documentTypeId) {
        state.register.documentTypeId = data.documentTypeId;
      }

      if (data.documentNumber) {
        state.register.documentNumber = data.documentNumber;
      }

      if (data.personType === true) {
        state.register.personType = "EMP";
      }

      if (data.personType === false) {
        state.register.personType = "PER";
      }
    },
    SET_STEP1: (state, value) => {
      state.step1 = value;
    },
    SET_STEP2: (state, value) => {
      state.step2 = value;
    },
    SET_STEP3: (state, value) => {
      state.step3 = value;
    },
    SET_STEP4: (state, value) => {
      state.step4 = value;
    },
  },
  getters: {
    REGISTER_STEP4: (state) => state.step4,
    REGISTER_STEP3: (state) => state.step3,
    REGISTER_STEP2: (state) => state.step2,
    REGISTER_STEP1: (state) => state.step1,
    REGISTER_DATA: (state) => state.register,
    REGISTER_EMAIL: (state) => state.register.email,
  },
  actions: {
    SET_REGISTER_STEP1: ({ commit }, value) => {
      commit("SET_STEP1", value);
    },
    SET_REGISTER_STEP2: ({ commit }, value) => {
      commit("SET_STEP2", value);
    },
    SET_REGISTER_STEP3: ({ commit }, value) => {
      commit("SET_STEP3", value);
    },
    SET_REGISTER_STEP4: ({ commit }, value) => {
      commit("SET_STEP4", value);
    },
    SET_REGISTER_DATA: ({ commit }, data) => {
      commit("SET_REGISTER", data);
    },
    POST_SEND_CODE: ({}, email) =>
      new Promise((resolve, reject) => {
        axios
          .post(`auth/sendCode`, { email })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
    POST_VERIFY_CODE: ({}, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post(`auth/verificationCode`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
    POST_REGISTER: ({}, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post(`clients`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
  },
};
