import * as Sentry from "@sentry/vue";
import { api, api2 } from "./api";
import store from "../stores/store";

const createMultipedidoProgramado = async (data) => {
  try {
    const { user } = store.state.userModule;
    const res = await api2.post(`client/${user.id}/multiorderProgrammed`, data);
    return res.data;
  } catch (error) {
    const { user } = store.state.userModule;
    Sentry.configureScope((scope) => {
      scope.setTransactionName("CreateMultipedidoProgramado");
      scope.setLevel("warning");
      scope.setExtra("horaIncidencia", new Date());
      scope.setExtra("userData", JSON.stringify({ ...user }));
      scope.setExtra("data", JSON.stringify(data));
      scope.setExtra("response", JSON.stringify(error.response));
      Sentry.captureException(new Error("Failed to create MultipedidoProgram"));
    });
    console.error(error);
    return error.response;
  }
};

const createMultipedidoPuntoScharff = async (data) => {
  try {
    const { user } = store.state.userModule;
    const res = await api2.post(
      `client/${user.id}/multiorderScharffPoint`,
      data
    );
    return res.data;
  } catch (error) {
    const { user } = store.state.userModule;
    Sentry.configureScope((scope) => {
      scope.setTransactionName("CreateMultipedidoProgramado");
      scope.setLevel("warning");
      scope.setExtra("horaIncidencia", new Date());
      scope.setExtra("userData", JSON.stringify({ ...user }));
      scope.setExtra("data", JSON.stringify(data));
      scope.setExtra("res", JSON.stringify(error.response));
      Sentry.captureException(new Error("Failed to create MultipedidoPS"));
    });
    console.error(error);
    return error.response;
  }
};

const deleteFavoriteLocation = async (locationId) => {
  try {
    const user = JSON.parse(localStorage.getItem("vuex"))?.userModule?.user;
    const res = await api.delete(
      `clients/${user.id}/favoriteLocations/${locationId}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const deleteFavoriteContact = async (contactId) => {
  try {
    const user = JSON.parse(localStorage.getItem("vuex"))?.userModule?.user;
    const res = await api.delete(
      `contacts/${user.id}/contacts/${contactId}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const emailInUseValidator = async (email) => {
  try {
    const resp = await api.post('clients/validateEmail', {
      email
    })
    return resp.data.Error // true si ya esta en uso
  } catch (e) {
    console.log({e})
    return {}
  }
}

export {
  createMultipedidoProgramado,
  createMultipedidoPuntoScharff,
  deleteFavoriteLocation,
  deleteFavoriteContact,
  emailInUseValidator,
};
