export default {
  state: {
    style: {
      generalDiv: "",
      contentDiv: "",
      expressDiv: "",
      mapSaio: false,
      ctQuoter: false,
      pmQuoter: false,
    },
  },
  mutations: {
    SET_GENERAL_DIV: (state, generalDiv) => {
      state.style.generalDiv = generalDiv;
    },
    SET_MAP_SAIO: (state, mapSaio) => {
      state.style.mapSaio = mapSaio;
    },
    SET_EXPRESS_DIV: (state, expressDiv) => {
      state.style.expressDiv = expressDiv;
    },
    // Div que contiene elcomponente princial
    SET_STYLE_CONTENT_DIV: (state, contentDiv) => {
      state.style.contentDiv = contentDiv;
    },
    SET_STYLE_SHOW_CT_QUOTER: (state, ctQuoter) => {
      state.style.ctQuoter = ctQuoter;
    },
    SET_STYLE_SHOW_PM_QUOTER: (state, pmQuoter) => {
      state.style.pmQuoter = pmQuoter;
    },
  },
  getters: {
    STYLE_GENERAL_DIV: (state) => state.style.generalDiv,
    STYLE_CONTENT_DIV: (state) => state.style.contentDiv,
    STYLE_SHOW_MAP_SAIO: (state) => state.style.mapSaio,
    STYLE_EXPRESS_DIV: (state) => state.style.expressDiv,
    STYLE_SHOW_CT_QUOTER: (state) => state.style.ctQuoter,
    STYLE_SHOW_PM_QUOTER: (state) => state.style.pmQuoter,
  },
  actions: {
    STYLE_CUSTOM_DIV: ({ commit }, customClassDiv) => {
      commit("SET_GENERAL_DIV", customClassDiv);
    },
    STYLE_SERVICES_W_MAP_DIV: ({ commit }) => {
      commit("SET_GENERAL_DIV", process.env.VUE_APP_STYLE_SERVICES_W_MAP);
    },
    STYLE_SERVICES_WO_MAP_DIV: ({ commit }) => {
      commit("SET_GENERAL_DIV", process.env.VUE_APP_STYLE_SERVICES_WO_MAP);
    },
    STYLE_HOME_DIV: ({ commit }) => {
      commit("SET_GENERAL_DIV", process.env.VUE_APP_STYLE_HOME);
    },
    STYLE_MAP_SAIO: ({ commit }, mapSaio) => {
      commit("SET_MAP_SAIO", mapSaio);
    },
    STYLE_EXPRESS_STEP_DIV_W_BG: ({ commit }) => {
      commit("SET_EXPRESS_DIV", process.env.VUE_APP_STYLE_EXPRESS_STEP_W_BG);
    },
    STYLE_EXPRESS_STEP_DIV_WO_BG: ({ commit }) => {
      commit("SET_EXPRESS_DIV", process.env.VUE_APP_STYLE_EXPRESS_STEP_WO_BG);
    },

    STYLE_CONTENT_DIV: ({ commit }, contentDiv) => {
      commit("SET_STYLE_CONTENT_DIV", contentDiv);
    },

    // Program
    STYLE_PROGRAMMED_STEP_DIV_ONE: ({ commit }) => {
      commit(
        "SET_STYLE_CONTENT_DIV",
        process.env.VUE_APP_STYLE_PROGRAM_STEP_ONE
      );
    },

    STYLE_PROGRAMMED_STEP_DIV_TWO: ({ commit }) => {
      commit(
        "SET_STYLE_CONTENT_DIV",
        process.env.VUE_APP_STYLE_PROGRAM_STEP_TWO
      );
    },
    STYLE_PROGRAMMED_STEP_DIV_TWO_GENERAL: ({ commit }) => {
      commit(
        "SET_GENERAL_DIV",
        process.env.VUE_APP_STYLE_PROGRAM_STEP_TWO_GENERAL
      );
    },

    STYLE_PROGRAMMED_STEP_DIV_THREE: ({ commit }) => {
      commit(
        "SET_STYLE_CONTENT_DIV",
        process.env.VUE_APP_STYLE_PROGRAM_STEP_THREE
      );
    },
    STYLE_PROGRAMMED_STEP_DIV_THREE_GENERAL: ({ commit }) => {
      commit(
        "SET_GENERAL_DIV",
        process.env.VUE_APP_STYLE_PROGRAM_STEP_THREE_GENERAL
      );
    },
    STYLE_SHOW_PM_QUOTER: ({ commit }, pmQuoter) => {
      commit("SET_STYLE_SHOW_PM_QUOTER", pmQuoter);
    },
    // C&T
    STYLE_SHOW_CT_QUOTER: ({ commit }, ctQuoter) => {
      commit("SET_STYLE_SHOW_CT_QUOTER", ctQuoter);
    },

    STYLE_SCHARFFPOINT_STEP_TWO_TYPE_ONE: ({ commit }) => {
      commit(
        "SET_GENERAL_DIV",
        process.env.VUE_APP_STYLE_SCHARFFPOINT_STEP_TWO_TYPE_ONE
      );
    },
  },
};
