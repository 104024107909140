/* eslint-disable no-empty-pattern */
/* eslint-disable prefer-promise-reject-errors */
import axios from "axios";

export default {
  state: {
    user: {},
    codeRestorePassword: null,
    uuid: null,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
    UPDATE_USER: (state, user) => {
      state.user.celular = user.phoneNumber
      state.user.firstLastName = user.lastName
      state.user.fullName = user.fullName
      state.user.name = user.name
    },
    SET_NEWCLIENT: (state, isNewClient) => {
      state.user.isNewClient = isNewClient;
    },
    SET_CODERESTOREPASSWORD: (state, user) => {
      state.codeRestorePassword = user;
    },
    SET_UUID: (state, uuid) => {
      state.uuid = uuid;
    },
  },
  getters: {
    GET_CODE_RESTPRE_PASSWORD: (state) => state.codeRestorePassword,
    IS_AN_EMBASSY_USER: (state) => {
      return state?.user?.culqiClientId === '1'
    },
    USER: (state) => state.user,
    UUID: (state) => state.uuid,
    AUTHENTICATED: (state) => {
      if (!state.user || !state.user.token) {
        return false;
      }
      return true;
    },
    NEWCLIENT: (state) => {
      return false
      // if (state.user?.isNewClient) {
      //   return true;
      // }
      // return false;
    },
  },
  actions: {
    RESTOREPASSWORD: (context, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post(`auth/forgotPasswordLink`, payload)
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            if (error.response === 401) {
              reject(true);
            } else {
              reject(false);
            }
          });
      }),
    FORGOTPASSWORD: (context, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post(`auth/forgotPassword`, payload)
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            if (error.response === 401) {
              reject(true);
            } else {
              reject(false);
            }
          });
      }),
    LOGINEXTERNAL: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post(`auth/clients/external`, payload)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_USER", response.data.result);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            if (error.response === 401) {
              reject(true);
            } else {
              reject(false);
            }
          });
      }),
    LOGINFROMTOKEN: ({ commit }, accessToken) =>
      new Promise((resolve, reject) => {
        axios
          .post(
            `auth/clients/signinaccesstoken`,
            {},
            { params: { accessToken } }
          )
          .then((response) => {
            if (response.status === 200) {
              commit("SET_USER", response.data.result);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            if (error.response === 401) {
              reject(true);
            } else {
              reject(false);
            }
          });
      }),
    // LOGIN: ({ commit }, payload) =>
    //   new Promise((resolve, reject) => {
    //     api
    //       .post(`auth/clients`, payload)
    //       .then(async (response) => {
    //         if (response.status === 200) {
    //           commit("SET_USER", response.data.result);
    //           const res2 = await login2(payload);
    //           commit("SET_USER2", res2);
    //           resolve(true);
    //         } else {
    //           resolve(false);
    //         }
    //       })
    //       .catch((error) => {
    //         if (error.response === 401) {
    //           reject(true);
    //         } else {
    //           reject(false);
    //         }
    //       });
    //   }),
    FB_LOGIN: ({}, {accessToken, queryString}) => {
      let param = ''
      if (queryString.length === 0) {
        param = 'source=Marketing'
      } else if (queryString.includes('source')) {
        param = queryString
      } else {
        param = `${queryString}&source=Marketing`
      }
      return new Promise((resolve, reject) => {
        axios.post(`auth/clients/fbaccesstoken?accessToken=${accessToken}&${param}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      })
    },
    GOOGLE_LOGIN: ({}, {accessToken, queryString}) => {
      let param = ''
      if (queryString.length === 0) {
        param = 'source=Marketing'
      } else if (queryString.includes('source')) {
        param = queryString
      } else {
        param = `${queryString}&source=Marketing`
      }
      return new Promise((resolve, reject) => {
        axios.post(`auth/clients/google?accessToken=${accessToken}&${param}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      })
    },
    APPLE_LOGIN: ({}, {payload, queryString}) => {
      let param = ''
      if (!queryString || queryString.length === 0) {
        param = 'source=Marketing'
      } else if (queryString.includes('source')) {
        param = queryString
      } else {
        param = `${queryString}&source=Marketing`
      }
      return new Promise((resolve, reject) => {
        axios.post(`auth/clients/apple/token?${param}`,
          payload
        )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      })
    },
    LOGOUT: ({ commit }) => {
      localStorage.clear();
      sessionStorage.clear();
      commit("SET_USER", null);
    },
    GET_USER_PROFILE: ({ rootState }) => {
      const { user } = rootState.userModule;
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/${user.id}`)
          .then((response) => {
            if (response.status === 200) {
              const profile = response.data.result;
              resolve(profile);
            } else {
              reject(new Error("Error al cargar el perfil"));
            }
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },

    GET_ZENDESK_TOKEN: ({ rootState }) => {
      const { user } = rootState.userModule;
      return new Promise((resolve, reject) => {
        axios
          .get(`zendesk/token/${user.id}`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const profile = response.data.result;
              resolve(profile);
            } else {
              reject(new Error("Error al obtener token de zendesk"));
            }
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },

    EDIT_USER_PROFILE: ({ rootState }, payload) => {
      const { user } = rootState.userModule;
      return new Promise((resolve, reject) => {
        axios
          .put(`clients/${user.id}`, payload, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data.result);
            } else {
              reject(new Error("Error al guardar los datos"));
            }
          })
          .catch((error) => {
            reject(error.response?.data?.message || "Ocurrio un error");
          });
      });
    },
    EDIT_USER_PASSWORD: ({ rootState }, payload) => {
      const { user } = rootState.userModule;
      return new Promise((resolve, reject) => {
        axios
          .post(`clients/${user.id}/changePassword`, payload, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data.result);
            } else if (response.status === 204) {
              reject(new Error("Faltan datos por completar"));
            } else {
              reject(new Error("Ocurrió un error en el servidor"));
            }
          })
          .catch((error) => {
            reject(error.response?.data?.message || "Ocurrio un error");
          });
      });
    },
    DELETE_USER_ACCOUNT: ({ rootState, dispatch }, userId) => {
      const { user } = rootState.userModule;
      return new Promise((resolve, reject) => {
        axios
          .delete(`clients/${userId}`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(new Error("Ocurrió un error en el servidor"));
            }
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              reject(new Error("Tu sesión ha expirado. Por favor, inicia sesión nuevamente."));
            } else if (error.response?.status === 403) {
              reject(new Error("No tienes permiso para eliminar este usuario"));
            } else if (error.response?.status === 404) {
              reject(new Error("Usuario no encontrado"));
            } else {
              reject(error.response?.data?.message || "Ocurrió un error al intentar eliminar el usuario");
            }
          });
      });
    },
    USER_SET_CODE_RESTORE_PASSWORD: ({ commit }, payload) => {
      commit("SET_CODERESTOREPASSWORD", payload);
    },
    USER_SET_USER: ({ commit }, payload) => {
      commit("SET_USER", payload);
    },
    USER_SET_NEWCLIENT: ({ commit }, payload) => {
      commit("SET_NEWCLIENT", payload);
    },
    USER_SET_UUID: ({ commit }, payload) => {
      commit("SET_UUID", payload);
    },
  },
};
