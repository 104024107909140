export default {
  state: {
    paymentResult: {},
  },
  mutations: {
    SET_PAYMENT_RESULT: (state, value) => {
      state.paymentResult = value;
    },
  },
  getters: {
    PAYMENT_RESULT: (state) => state.paymentResult,
  },
  actions: {
    SET_PAYMENT_RESULT: ({ commit }, payload) => {
      commit("SET_PAYMENT_RESULT", payload);
    },
  },
};
