import { api, api2 } from "./api";
// import { api as apiTracking } from "./apiTracking";

// eslint-disable-next-line import/prefer-default-export
export async function downloadGuide(id, numero, multiOrder = true) {
  const response = await api2({
    url: `guide?OrderId=${id}&MultiOrder=${multiOrder}`,
    method: "GET",
    responseType: "blob", // important
  });
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `Guia ${numero}.pdf`); // or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export async function downloadInvoice(numero, numFactura, multiOrder = true) {
  const response = await api2({
    url: `downloadInvoice?Code=${numero}&MultiOrder=${multiOrder}`,
    method: "GET",
    responseType: "blob", // important
  });
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${numero}_${numFactura}.pdf`); // or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function getGuideByNumber(guideNumber) {
  return new Promise((resolve, reject) => {
    api2
      .post(`tracking/consultGuide`, { guide: guideNumber })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.error);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getOderData (code) {
  try {
    const resp = await api.get(`orders/byId?orderId=${code}`)
    return resp.data.result
  } catch (e) {
    throw new Error(e)
  }
}