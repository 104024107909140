/* eslint-disable prefer-promise-reject-errors */
import axios from "axios";
import * as Sentry from "@sentry/vue";

const initialState = {
  progressNav: {
    progress: "40",
    stepsText: "Paso 1 de 3",
    title: "Envío express",
    visiblePc: "true",
    showButtonBack: "true",
    helpModalId: "modalOnboardingApp",
    step: 1,
    show: true,
  },
  order: {
    origin: {
      description: "",
      additionalAddress: "",
      addressDetail: "",
      lat: null,
      lng: null,
      favorite: false,
    },
    destinations: [],
    contactOrigin: null,
    contactDestination: [],
    currentContactDestinationIndex: null,
    categories: [],
    categoryId: 0,
    categoryName: "",
    productValue: 0,
    voucher: null,
    card: null,
    tip: 0,
    coupon: null,
    fare: null,
  },
  mapObject: null,
};
export default {
  state: { ...initialState },
  mutations: {
    SET_MAP_OBJECT: (state, map) => {
      state.mapObject = map;
    },
    RESET_STATE: (state) => {
      // eslint-disable-next-line no-param-reassign, no-unused-vars
      state = { ...state, ...initialState };
    },
    SET_FARE: (state, fare) => {
      state.order.fare = fare;
    },
    SET_COUPON: (state, coupon) => {
      state.order.coupon = coupon;
    },
    SET_TIP: (state, tip) => {
      state.order.tip = tip;
    },
    SET_CARD: (state, card) => {
      state.order.card = card;
    },
    SET_VOUCHER: (state, voucher) => {
      state.order.voucher = voucher;
    },
    SET_CATEGORIES: (state, categories) => {
      state.order.categories = categories;
    },
    SET_CATEGORY_ID: (state, categoryId) => {
      state.order.categoryId = categoryId;
      state.order.categoryName = state.order.categories.find(
        (x) => x.id === categoryId
      )?.text;
    },
    SET_PRODUCT_VALUE: (state, productValue) => {
      state.order.productValue = productValue;
    },
    SET_DEFAULT_DATA: (state) => {
      state.order.origin.description = "";
      state.order.origin.lat = null;
      state.order.origin.lng = null;
      state.order.origin.favorite = false;

      state.order.destinations = [];
      state.order.contactOrigin = null;
      state.order.contactDestination = [];
      state.order.currentContactDestinationIndex = null;
    },
    SET_ORIGIN: (state, origin) => {
      state.order.origin = origin;
    },
    SET_DESTINATIONS: (state, destinations) => {
      state.order.destinations = destinations;
    },
    SET_CHECKED_CONTACT_ID: (state, contactId) => {
      state.order.checkedContactId = contactId;
    },
    SET_CONTACT_CLEAN: (state) => {
      state.order.contactDestination = [];
      state.order.contactOrigin = null;
    },
    SET_CURRENT_CONTACT_DEST_INDEX: (state, destIndex) => {
      state.order.currentContactDestinationIndex = destIndex;
    },
    SET_CONTACT_DESTINATION: (state, destinationContact) => {
      if (state.order.contactDestination[destinationContact.destinationIndex]) {
        state.order.contactDestination[
          destinationContact.destinationIndex
        ].name = destinationContact.name;
        state.order.contactDestination[
          destinationContact.destinationIndex
        ].phone = destinationContact.phone;
      } else {
        state.order.contactDestination.push(destinationContact);
      }
    },
    SET_CONTACT_ORIGIN: (state, contactOrigin) => {
      state.order.contactOrigin = contactOrigin;
    },
    SET_PROGRESS_NAV: (state, progressNav) => {
      state.progressNav = progressNav;
    },
    SET_PROGRESS_NAV_ATTR: (state, attr) => {
      if (attr) {
        state.progressNav.progress =
          attr.progress ?? state.progressNav.progress;
        state.progressNav.stepsText =
          attr.stepsText ?? state.progressNav.stepsText;
        state.progressNav.title = attr.title ?? state.progressNav.title;
        state.progressNav.visiblePc =
          attr.visiblePc ?? state.progressNav.visiblePc;
        state.progressNav.showButtonBack =
          attr.showButtonBack ?? state.progressNav.showButtonBack;
        state.progressNav.helpModalId =
          attr.helpModalId ?? state.progressNav.helpModalId;
        state.progressNav.step = attr.step ?? state.progressNav.step;
        state.progressNav.show = attr.show ?? state.progressNav.show;
      }
    },
    SET_PROGRESS_NAV_VISIBLEPC: (state, attr) => {
      state.progressNav.visiblePc = attr;
    },
    SET_PROGRESS_NAV_VISIBILITY: (state, show) => {
      state.progressNav.show = show;
    },
    SET_PROGRESS_NAV_STEP: (state, step) => {
      const stringStep = step > 3 ? 3 : step;
      state.progressNav.stepsText = `Paso ${stringStep} de 3`;
      state.progressNav.title = "Envío express";
      state.progressNav.showButtonBack = "true";
      state.progressNav.helpModalId = "modalOnboardingApp";

      if (step === 1) {
        state.progressNav.progress = "40";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 1;
        state.progressNav.show = true;
      } else if (step === 2) {
        state.progressNav.progress = "70";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 2;
        state.progressNav.show = true;
      } else if (step === 3) {
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 3;
        state.progressNav.show = true;
      } else {
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "false";
        state.progressNav.step = step;
        state.progressNav.show = false;
      }
    },
    SET_ORDER: (state, order) => {
      state.order = order;
    },
  },
  getters: {
    EXPRESS_MAP_OBJECT: (state) => state.mapObject,
    EXPRESS_FARE: (state) => state.order.fare,
    EXPRESS_ORDER: (state) => state.order,
    EXPRESS_COUPON: (state) => state.order.coupon,
    EXPRESS_TIP: (state) => state.order.tip,
    EXPRESS_CARD: (state) => state.order.card,
    EXPRESS_VOUCHER: (state) => state.order.voucher,
    EXPRESS_CATEGORIES: (state) => state.order.categories,
    EXPRESS_CATEGORY_ID: (state) => state.order.categoryId,
    EXPRESS_CATEGORY_NAME: (state) => state.order.categoryName,
    EXPRESS_PRODUCT_VALUE: (state) => state.order.productValue,
    EXPRESS_ORIGIN: (state) => state.order.origin,
    EXPRESS_DESTINATIONS: (state) => state.order.destinations,
    EXPRESS_CHECKED_CONTACT_ID: (state) => state.order.checkedContactId,
    EXPRESS_CURRENT_CONTACT_DESTINATION_INDEX: (state) =>
      state.order.currentContactDestinationIndex,
    EXPRESS_DESTINATION_CONTACT: (state) => state.order.contactDestination,
    EXPRESS_ORIGIN_CONTACT: (state) => state.order.contactOrigin,
    EXPRESS_PROGRESS_NAV: (state) => state.progressNav,
    EXPRESS_STEP: (state) => state.progressNav.step,
  },
  actions: {
    EXPRESS_MAP: ({ commit }, map) => {
      commit("SET_MAP_OBJECT", map);
    },
    EXPRESS_RESET: ({ commit }) => {
      commit("RESET_STATE");
    },
    EXPRESS_COUPON: ({ commit }, coupon) => {
      commit("SET_COUPON", coupon);
    },
    EXPRESS_TIP: ({ commit }, tip) => {
      commit("SET_TIP", tip);
    },
    EXPRESS_CARD: ({ commit }, card) => {
      commit("SET_CARD", card);
    },
    EXPRESS_VOUCHER: ({ commit }, voucher) => {
      commit("SET_VOUCHER", voucher);
    },
    EXPRESS_CATEGORIES: ({ commit }, value) => {
      commit("SET_CATEGORIES", value);
    },
    EXPRESS_CATEGORY_ID: ({ commit }, value) => {
      commit("SET_CATEGORY_ID", value);
    },
    EXPRESS_PRODUCT_VALUE: ({ commit }, value) => {
      commit("SET_PRODUCT_VALUE", value);
    },
    EXPRESS_DEFAULT_DATA: ({ commit }) => {
      commit("SET_DEFAULT_DATA");
    },
    EXPRESS_ORDER_ORIGINS: ({ commit }, origin) => {
      commit("SET_ORIGINS", origin);
    },
    EXPRESS_ORDER_DESTINATIONS: ({ commit }, destinations) => {
      commit("SET_DESTINATIONS", destinations);
    },
    EXPRESS_ORDER_CHECKED_CONTACT_ID: ({ commit }, contactId) => {
      commit("SET_CHECKED_CONTACT_ID", contactId);
    },
    EXPRESS_ORDER_CONTACT_CLEAN: ({ commit }) => {
      commit("SET_CONTACT_CLEAN");
    },
    EXPRESS_ORDER_CURRENT_CONTACT_DEST_INDEX: ({ commit }, destIndex) => {
      commit("SET_CURRENT_CONTACT_DEST_INDEX", destIndex);
    },
    EXPRESS_ORDER_DESTINATION_CONTACT: ({ commit }, destinationContact) => {
      commit("SET_CONTACT_DESTINATION", destinationContact);
    },
    EXPRESS_ORDER_ORIGIN_CONTACT: ({ commit }, originContact) => {
      commit("SET_CONTACT_ORIGIN", originContact);
    },
    EXPRESS_PROGRESS_NAV_ATTR: ({ commit }, attr) => {
      commit("SET_PROGRESS_NAV_ATTR", attr);
    },
    EXPRESS_PROGRESS_NAV_VISIBLEPC: ({ commit }, attr) => {
      commit("SET_PROGRESS_NAV_VISIBLEPC", attr);
    },
    EXPRESS_PROGRESS_NAV_VISIBILITY: ({ commit }, show) => {
      commit("SET_PROGRESS_NAV_VISIBILITY", show);
    },
    EXPRESS_PROGRESS_NAV_STEP: ({ commit }, step) => {
      commit("SET_PROGRESS_NAV_STEP", step);
    },
    EXPRESS_CALCULATE_FARE: ({ commit, getters, rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        const order = getters.EXPRESS_ORDER;
        const coupon = order.coupon
          ? {
              Type: order.coupon.type,
              Value: order.coupon.value,
            }
          : null;

        const locationPoints = [];
        locationPoints.push({
          Lat: order.origin.lat,
          Lng: order.origin.lng,
        });

        order.destinations.forEach((dest) => {
          locationPoints.push({
            Lat: dest.lat,
            Lng: dest.lng,
          });
        });

        if (!user) {
          reject(true);
        } else {
          const payload = {
            Coupon: coupon,
            LocationPoints: locationPoints,
            UserId: user.id,
            IsServiceExpress: true,
            Tip: order.tip,
          };
          axios
            .post(`fare`, payload, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              commit("SET_FARE", response.data.result);

              resolve(response);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                tags: {
                  ErrorType: "Prioritize",
                },
              });
              reject(error.response);
            });
        }
      }),
    EXPRESS_CREATE_FARE: ({ state, rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        const UUID = rootState.userModule.uuid;

        if (!user) {
          reject(true);
        } else {
          const { order } = state;
          const addresses = [];
          const orden = 1;
          const originLocation = {
            Direccion: order.origin.description,
            DireccionAdicional: order.origin.additionalAddress,
            Detalle: order.origin.addressDetail,
            Orden: orden,
            Tipo: 1,
            Contacto: order.contactOrigin.name,
            Celular: order.contactOrigin.phone,
            CorreoContacto: order.contactOrigin.email,
            DNIContacto: order.contactOrigin.documentNumber,
            Location: {
              Lat: order.origin.lat,
              Lng: order.origin.lng,
            },
          };
          addresses.push(originLocation);

          order.destinations.forEach((dest, index) => {
            const contactDestination = order.contactDestination[index];
            addresses.push({
              Direccion: dest.description,
              Orden: orden + index + 1,
              Tipo: 2,
              Contacto: contactDestination.name,
              Celular: contactDestination.phone,
              CorreoContacto: contactDestination.email,
              DNIContacto: contactDestination.documentNumber,
              Location: {
                Lat: dest.lat,
                Lng: dest.lng,
              },
              DireccionAdicional: dest.additionalAddress,
              Detalle: dest.addressDetail,
            });
          });

          // refactor bill
          const bill = {
            RUC:
              order.voucher.typeVoucher === "Factura"
                ? order.voucher.documentNumber
                : null,
            BusinessName:
              order.voucher.typeVoucher === "Factura"
                ? order.voucher.name
                : null,
            ContactName:
              order.voucher.typeVoucher === "Factura"
                ? order.voucher.name
                : null,
            ContactEmail:
              order.voucher.typeVoucher === "Factura"
                ? order.voucher.email
                : null,
            DNI:
              order.voucher.typeVoucher === "Boleta"
                ? order.voucher.documentNumber
                : null,
            Name:
              order.voucher.typeVoucher === "Boleta"
                ? order.voucher.name
                : null,
            Email:
              order.voucher.typeVoucher === "Boleta"
                ? order.voucher.email
                : null,
            Cellphone: order.voucher.phone,
          };

          const orderRequest = {
            packageSize: "Pequeño", // todo
            packageValue: order.productValue,
            packageDescription: order.categoryName,
            serviceTypeId: 1, // refactor
            propina: order.tip,
            isServiceExpress: true,
            isServiceProgrammed: false,
            addresses,
            isReturnOrigin: false, // todo
            isInsuranceAccepted: false, // todo,
            bill,
            coupon: order.coupon ? order.coupon.code : null,
            creditCardId: order?.card?.cardId,
            fragil: false, // todo
            UUID,
          };

          axios
            .post(`clients/${user.id}/orders`, orderRequest, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              Sentry.configureScope((scope) => {
                scope.setTransactionName("CreateExpress");
                scope.setLevel("warning");
                scope.setExtra("data", JSON.stringify(orderRequest));
                scope.setExtra("res", JSON.stringify(response));
                Sentry.captureException(
                  new Error("Success create MultipedidoProgram")
                );
              });
              resolve(response);
            })
            .catch((error) => {
              Sentry.configureScope((scope) => {
                scope.setTransactionName("CreateExpressError");
                scope.setLevel("warning");
                scope.setExtra("data", JSON.stringify(orderRequest));
                scope.setExtra("res", JSON.stringify(error.response));
                Sentry.captureException(
                  new Error("Failed create MultipedidoProgram")
                );
              });
              reject(error.response);
            });
        }
      }),
  },
};