import Vue from "vue";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_APIURL
});

const user = JSON.parse(localStorage.getItem("vuex"))?.userModule?.user;

if (user?.token) {
  api.defaults.headers.common = {
    Authorization: `Bearer ${user ? user.token : ""}`
  };
}

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      if (!localStorage.getItem("showedExpiredSession")) {
        localStorage.setItem("showedExpiredSession", true);
        setTimeout(() => {
          Vue.swal({
            title: "Sesión Expirada",
            html: "Por favor vuelve a iniciar sesión.",
            icon: "warning",
            confirmButtonText: "Aceptar",
            allowOutsideClick: false
          }).then(() => {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
          });
        }, 1000);
      }
    }else {
      // handle other errors
      if (error.message === 'Network Error') {
        // show a message indicating a network error
        Vue.swal({
          title: "Error de Conexión",
          html: "Lo sentimos, parece que hay un problema de conexión. Por favor, comprueba tu conexión a Internet y vuelve a intentarlo más tarde.",
          icon: "error",
          confirmButtonText: "Aceptar",
          allowOutsideClick: false,
        });
      } else {
        // show a generic error message
        Vue.swal({
          title: "Error",
          html: "Lo sentimos, ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.",
          icon: "error",
          confirmButtonText: "Aceptar",
          allowOutsideClick: false,
        });
      }
    }
    return Promise.reject(error);
  }
);

const api2 = axios.create({
  baseURL: process.env.VUE_APP_APIURL2
});

if (user?.token) {
  api2.defaults.headers.common = {
    Authorization: `Bearer ${user ? user.token : ""}`
  };
}

api2.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      if (!localStorage.getItem("showedExpiredSession")) {
        localStorage.setItem("showedExpiredSession", true);
        setTimeout(() => {
          Vue.swal({
            title: "Sesión Expirada",
            html: "Por favor vuelve a iniciar sesión.",
            icon: "warning",
            confirmButtonText: "Aceptar",
            allowOutsideClick: false
          }).then(() => {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = `${process.env.VUE_APP_WEBURL}signIn`;
          });
        }, 1000);
      }
    }else {
      console.group('3', error)
      // handle other errors
      if (error.message === 'Network Error') {
        // show a message indicating a network error
        Vue.swal({
          title: "Error de Conexión",
          html: "Lo sentimos, parece que hay un problema de conexión. Por favor, comprueba tu conexión a Internet y vuelve a intentarlo más tarde.",
          icon: "error",
          confirmButtonText: "Aceptar",
          allowOutsideClick: false,
        });
      } else {
        // show a generic error message
        Vue.swal({
          title: "Error",
          html: "Lo sentimos, ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.",
          icon: "error",
          confirmButtonText: "Aceptar",
          allowOutsideClick: false,
        });
      }
    }
    return Promise.reject(error);
  }
);

// eslint-disable-next-line import/prefer-default-export
export { api, api2 };
