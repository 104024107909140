import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import UserModule from "./modules/user";
import PaymentModule from "./modules/payment";
import StyleModule from "./modules/style";
import ExpressModule from "./modules/express";
import ProgrammedModule from "./modules/programmed";
import ScharffPointModule from "./modules/scharffpoint";
import CompraTraeModule from "./modules/compratrae";
import HelperModule from "./modules/helper";
import HistoryModule from "./modules/history";
import GeneralApiCallsModule from "./modules/generalApiCalls";
import RegisterModule from "./modules/register";
import serviceInfo from "./modules/serviceInfo";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userModule: UserModule,
    PaymentModule,
    styleModule: StyleModule,
    expressModule: ExpressModule,
    programmedModule: ProgrammedModule,
    scharffPointModule: ScharffPointModule,
    compraTraeModule: CompraTraeModule,
    helperModule: HelperModule,
    historyModule: HistoryModule,
    generalApiCallsModule: GeneralApiCallsModule,
    registerModule: RegisterModule,
    serviceInfoModule: serviceInfo,
  },
  plugins: [
    createPersistedState({
      paths: ["userModule"],
    }),
  ],
});
