import { api } from "./api";
import store from "../stores/store";

const { user } = store.state.userModule;

// eslint-disable-next-line import/prefer-default-export
export async function sendEvent(eventId = 1) {
  const response = await api.post(`/events/${user.id}/userEvent/${eventId}`);
  return response;
}
