// eslint-disable-next-line import/no-cycle
import { api2 } from "./api";

const readFileProgrammed = async (formData) => {
  try {
    const res = await api2.post("massiveLoad/readFileProgrammed", formData);
    return res.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

const readFilePSDropOff = async (formData) => {
  try {
    const res = await api2.post("massiveLoad/readFilePSDropOff", formData);
    return res.data;
  } catch (error) {
    console.error(error.response);
    return error.response;
  }
};

const readFilePSPickUp = async (formData) => {
  try {
    const res = await api2.post("massiveLoad/readFilePSPickUp", formData);
    return res.data;
  } catch (error) {
    console.error(error.response);
    return error.response;
  }
};

export { readFileProgrammed, readFilePSDropOff, readFilePSPickUp };
