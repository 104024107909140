import { api } from "./api";
import store from "../stores/store";

const { user } = store.state.userModule;

export async function getCompraTraerOrders(orderId) {
  const res = await api.request({
    method: "get",
    url: `/clients/${user.id}/compraTraeV2Orders/${orderId}`,
  });
  return res.result || false;
}

export async function sendTrackingNumbers(data) {
  const res = await api.request({
    method: "post",
    url: `/clients/${user.id}/postCompraTraeTrackingNumber`,
    data,
  });
  return res || false;
}
